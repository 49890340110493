import React from "react"
import _ from "lodash"

import { getData } from "../utils"
import BlogPostFeedItem from "./BlogPostFeedItem"

export default class BlogFeedItemFilter extends React.Component {
  render() {
    let section = _.get(this.props, "section_blog_feed", null)
    let section_tag = _.get(this.props, "section_tag", null)
    let post = _.get(this.props, "post_page", null)
    return section_tag ? (
      _.map(_.get(post, "frontmatter.tags", null), (tag, tag_idx) => {
        let post_tag = getData(this.props.pageContext.site.data, tag)
        return (
          post_tag.id === _.get(section_tag, "id", null) && (
            <BlogPostFeedItem
              key={tag_idx}
              {...this.props}
              section_blog_feed={section}
              post_page={post}
            />
          )
        )
      })
    ) : (
      <BlogPostFeedItem
        {...this.props}
        section_blog_feed={section}
        post_page={post}
      />
    )
  }
}
