import React from "react"
import _ from "lodash"

import { getData, getPages } from "../utils"
import BlogFeedItemFilter from "./BlogFeedItemFilter"

export default class SectionBlogFeed extends React.Component {
  render() {
    let section = _.get(this.props, "section", null)
    let section_tag = false
    let posts_all = getPages(this.props.pageContext.pages, "/posts")
    let posts_sorted = _.orderBy(posts_all, "frontmatter.date", "desc")
    let show_recent = _.get(section, "show_recent", null) || false
    let recent_count = _.get(section, "recent_count", null) || 0
    let post_count = 0
    if (_.get(section, "tag", null)) {
      section_tag = getData(
        this.props.pageContext.site.data,
        _.get(section, "tag", null)
      )
    }
    return (
      <section>
        <div className='container'>
          <div className='grid'>
            {_.map(posts_sorted, (post, post_idx) => {
              let is_post = false
              if (_.get(post, "frontmatter.template", null) === "post") {
                is_post = true
              }
              return (
                <React.Fragment key={post_idx + ".1"}>
                  {is_post &&
                    (show_recent === false || post_count < recent_count) &&
                    (() => {
                      post_count = post_count + 1
                      return (
                        <BlogFeedItemFilter
                          key={post_idx}
                          {...this.props}
                          section_blog_feed={section}
                          post_page={post}
                          section_tag={section_tag}
                        />
                      )
                    })()}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}
