import React, { useState, useEffect } from "react"
import {
  enable as enableDarkMode,
  disable as disableDarkMode
} from "darkreader"
// https://github.com/darkreader/darkreader

export default function DarkModeToggle() {
  const [themeState, setThemeState] = useState(false)

  // runs on componentDidMount, checks localStorage and sets theme
  useEffect(() => {
    const getTheme = localStorage.getItem("Theme")
    if (getTheme === "dark") {
      setThemeState(true)
      enableDarkMode()
    }
  }, [])

  // runs when themeState changes
  useEffect(() => {
    if (themeState) {
      localStorage.setItem("Theme", "dark")
      enableDarkMode()
    } else {
      localStorage.setItem("Theme", "light")
      disableDarkMode()
    }
  }, [themeState])

  return (
    <div>
      <div>
        <button
          title='Toggle dark mode'
          className='toggle-container'
          onClick={() => setThemeState(!themeState)}>
          <div
            style={{
              backgroundColor: "#f2973a"
            }}
            className={`sun ${themeState ? "visible" : ""}`}
          />
          <div className={`${themeState ? "" : "toggle-darkmode"}`}>
            <div className={`moon ${themeState ? "" : "visible"}`}>
              <div className='star' />
              <div className='star small' />
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}
// const [hoverState, setHoverState] = useState(false)
// <div
//   // onMouseEnter={() => setHoverState(true)}
//   // onMouseLeave={() => setHoverState(false)}
//   style={{
//     backgroundColor: "#f2973a"
//     // ...(hoverState && {
//     //   boxShadow: `0 0 1px 0.5em rgba(#f2973a, 0.2)`
//     // })
//   }}
//   className={`sun ${themeState ? "visible" : ""}`}
// />
