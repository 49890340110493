import React from "react"
import _ from "lodash"
import moment from "moment-strftime"

import { Link, withPrefix } from "../utils"

export default class BlogPostFeedItem extends React.Component {
  render() {
    let post = _.get(this.props, "post_page", null)

    return (
      <div>
        <article className='post post-card'>
          <div className='post-inside'>
            <div className='post-column'>
              {_.get(post, "frontmatter.thumb_img_path", null) && (
                <Link
                  className='post-thumbnail'
                  to={withPrefix(_.get(post, "url", null))}>
                  <img
                    src={withPrefix(
                      _.get(post, "frontmatter.thumb_img_path", null)
                    )}
                    alt={_.get(post, "frontmatter.thumb_img_alt", null)}
                  />
                </Link>
              )}
            </div>
            <div className='post-wide-column'>
              <header className='post-header'>
                <h2 className='post-title'>
                  <Link
                    to={withPrefix(_.get(post, "url", null))}
                    rel='bookmark'>
                    {_.get(post, "frontmatter.title", null)}
                  </Link>
                </h2>
              </header>
              {_.get(post, "frontmatter.excerpt", null) && (
                <div className='post-content'>
                  <p>{_.get(post, "frontmatter.excerpt", null)}</p>
                </div>
              )}
              <footer className='post-meta'>
                <time
                  className='published'
                  dateTime={moment(
                    _.get(post, "frontmatter.date", null)
                  ).strftime("%Y-%m-%d %H:%M")}>
                  {moment(_.get(post, "frontmatter.date", null)).strftime(
                    "%B %d, %Y"
                  )}
                </time>
              </footer>
            </div>
          </div>
        </article>
      </div>
    )
  }
}
